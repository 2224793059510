import {post} from '@/services/api';
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: 'ResetPassword',
  mixins: [show_alert_mixin],
  data: () => ({
    token: null,
    model: {
      password: '',
      rePassword: ''
    },
    userID: null
  }),
  mounted() {
    const params = this.$route.params;
    if (params.token) {
      this.token = params.token;
      this.verifyToken();
    }
  },
  methods: {
    async verifyToken() {
      post('auth/verify-reset-token-password', {token: this.token})
        .then(({data}) => {
          this.userID = data.user_id;
        })
        .catch(async (err) => {
          if (err.response) {
            this.showAlertNotification('The verification code is invalid.', 'error')
          }
        }).finally(() => this.$swal.close());
    },
    sendPassword() {
      post('auth/update-password', {
        password: this.model.rePassword,
        id: this.userID
      }).then(async () => {
        this.showAlertNotification('Process done correctly')
        await this.$router.push('/')
      });
    }
  },
};
